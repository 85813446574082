<template>
  <input class="location-search"
         :value="value"
         :input="value => $emit('update:value', value)"
         :placeholder="placeholder"
         ref="search"
  />
</template>

<script>
import { GeographicLonger, BoundsGoogleToApi } from '@/utils/map.js';

export default {
  name: 'SearchPlaceGoogle',
  data() {
    return {
      searchBox: null,
    };
  },
  props: {
    value: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
  },
  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      this.searchBox = new google.maps.places.SearchBox(this.$refs.search);
      this.searchBox.addListener('places_changed', this.placeChanged)
    });
  },
  methods: {
    // common method when search button click
    search() {
      this.placeChanged();
    },
    placeChanged() {
      let places = this.searchBox.getPlaces();
      if (places.length == 0) {
        return;
      }
      let place = places[0];

      let position = GeographicLonger(place.geometry.location);
      let bounds = BoundsGoogleToApi(place.geometry.viewport);

      let eventPlace = {
        position,
        bounds,
      };
      this.$emit('selectPlace', eventPlace);
    },
  },
}
</script>