<template>
  <div class="search-nominatim">
    <input class="location-search" :value="value" @input="input" @compositionend="input" @blur="blur" :placeholder="placeholder"/>
    <div v-if="result != null" class="nominatim-results" @mouseout="selectIndex = null">
      <template v-if="result.length > 0">
        <div v-for="(item, index) in result"
             :class="{ 'nominatim-place': true, 'activate': index == selectIndex }"
             :key="item.place_id"
             @click="selectPlace(index)"
             @mouseover="selectIndex = index"
        >
          <div class="nominatim-place-icon" :title="item.display_name">
            <img v-if="item.icon != null && item.icon.startsWith('/')" :src="apiHost + item.icon" />
            <img v-else :src="item.icon" />
          </div>
          <label>{{ item.display_name }}</label>
        </div>
      </template>
      <div v-if="loading" class="nominatim-results-loading">
        {{ $t('page.setting.loading') }}
      </div>
      <div class="nominatim-results-footer">{{ copyright }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SearchPlaceNominatim',
  data() {
    return {
      result: null,
      selectIndex: null,
      loading: false,
      loadFail: false,
    };
  },
  props: {
    value: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    copyright: {
      default: '© OpenStreetMap contributors',
    },
  },
  computed: {
    apiEndPoint() {
      if (process.env.NOMINATIM_ENDPOINT != null) {
        return process.env.NOMINATIM_ENDPOINT;
      } 
      return 'https://nominatim.openstreetmap.org/';
    },
    apiHost() {
      let protocolPosition = this.apiEndPoint.indexOf(':') + 3;
      let hostPosition = this.apiEndPoint.indexOf('/', protocolPosition);
      return this.apiEndPoint.slice(0, hostPosition);
    },
    typeToSearch() {
      let envSetting = false;
      if (process.env.NOMINATIM_TYPE_TO_SEARCH != null) {
        envSetting = JSON.parse(process.env.NOMINATIM_TYPE_TO_SEARCH.toLowerCase());
      }
      
      if (this.apiEndPoint.toLowerCase().includes('openstreetmap')) {
        if (envSetting) {
          console.warn('detect openstreetmap server, disable type to search');
        }
        return false;
      }
      return envSetting;
    },
  },
  methods: {
    // common method when search button click
    search(keyword) {
      if (keyword == null) {
        keyword = this.value;
      }

      this.$set(this, 'result', null);
      if (keyword == null || keyword == '') {
        return;
      }

      this.loading = true;
      this.loadFail = false;
      this.$set(this, 'result', []);
      axios.get(`${this.apiEndPoint}search/${keyword}?format=json`).then((response) => {
        this.$set(this, 'result', response.data);
        this.loading = false;
      }).catch((error) => {
        this.$showFail(error);
        this.loading = false;
        this.loadFail = true;
      });
    },
    input(event) {
      let keyword = event.target.value;
      if (keyword === this.value) {
        return;
      }
      // for CJKT with IME
      if (event.isComposing || event.keyCode === 229) {
        return;
      }
      this.$emit('input', keyword);
      if (this.typeToSearch) {
        this.search(keyword);
      }
    },
    blur(event) {
      let keyword = event.target.value;
      this.$emit('input', keyword);
      if (this.typeToSearch) {
        return;
      }
      this.search(keyword);
    },
    selectPlace(index) {
      if (index == null) {
        index = this.selectIndex;
      }
      let eventPlace = this.convertNominatimToPlace(this.result[index]);
      this.$emit('selectPlace', eventPlace);
      this.$emit('input', this.result[index].display_name);
      this.$set(this, 'result', null);
      this.selectIndex = null;
    },
    keyboardPress(event) {
      let step;
      switch (event.key) {
        case 'ArrowDown':
          step = +1;
          break;
        case 'ArrowUp':
          step = -1;
          break;
        case 'Enter':
          this.selectPlace();
        default:
          return;
      }
      let nextSelect = this.selectIndex + step;
      if (this.selectIndex == null || nextSelect < 0) {
        nextSelect = 0;
      } else if (nextSelect === this.result.length) {
        nextSelect = this.result.length - 1;
      }
      this.selectIndex = nextSelect;
      event.preventDefault();
      return false;
    },
    convertNominatimToPlace(item) {
      return {
        position: {
          latitude: parseFloat(item.lat),
          longitude: parseFloat(item.lon),
        },
        bounds: [
          parseFloat(item.boundingbox[0]),
          parseFloat(item.boundingbox[2]),
          parseFloat(item.boundingbox[1]),
          parseFloat(item.boundingbox[3]),
        ],
      }
    },
  },
  created() {
    window.addEventListener('keydown', this.keyboardPress);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyboardPress);
  },

}
</script>

<style lang="scss">
.search-nominatim {

  .nominatim-results {
    background-color: white;
    border-radius: 2px;

    .nominatim-place {
      cursor: pointer;
      height: 31px;
      border-top-width: 1px;
      border-top-color: #E6E6E6;
      border-top-style: solid;
      line-height: 30px;
      padding-left: 5px;
      padding-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      &.activate {
        background-color: #EBF2FE;
      }

      .nominatim-place-icon {
        vertical-align: middle;
        width: 20px;
        display: inline-block;

        img {
          vertical-align: middle;
        }
      }
    }

    .nominatim-results-loading {
      height: 40px;
      line-height: 40px;
      text-align: center;
    }

    .nominatim-results-footer {
      text-align: right;
      color: #767676;
      padding: 1px;
      font-size: 12px;
    }
  }
}
</style>