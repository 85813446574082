<template>
  <div class="overlay location-map-mode-selector">
    <div v-for="(item, index) in items"
         :key="index"
         :class="{'button-container': true, activate: (value == item)}">
      <button :class="`image-button ${item}`" v-on:click="changeMode(item)"></button>  
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TypeSelector',
    data() {
      return {
      };
    },
    props: {
      value: {
        required: true,
      },
      items: {
        required: true,
        type: Array,
      },
    },
    computed: {
    },
    mounted() {
    },
    methods: {
      changeMode(type) {
        this.$emit('input', type);
      }
    },
  };
</script>

<style lang="scss">
.overlay.location-map-mode-selector {
  top: 141px;
  left: initial;
  right: 0px;
  width: 63px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px 0 0 4px;

  .button-container {
    border: 0;
    margin: 0;
    height: 55px;
    width: 63px;
    position: relative;
  }

  .button-container.activate {
    // background-color: rgba(0, 0, 0, 0.5);
    border-right: 6px solid #ffc900;
  }

  .button-container:first-child {
    border-top-left-radius: 4px;
  }

  .button-container:last-child {
    button{
      border-bottom-style: none;
    }
    border-bottom-left-radius: 4px;
  }

  button {
    margin-left: 3px;
    margin-right: 4px;
    color: white;
    width: 56px;
    height: 55px;

    border-bottom-style: solid;
    border-width: 1px;
  }

  button:focus {
    outline:0;
  }
  
  button.image-button.fence {
    background-image: url('/asserts/icon/location-geo-fence-icon.svg');
  }

  button.image-button.no_fly {
    background-image: url('/asserts/icon/location-nofly-zone.svg');
  }

  button.image-button.altitude_limit {
    background-image: url('/asserts/icon/location-height-restriction-icon.svg');
  }

  button.image-button.obstacle {
    background-image: url('/asserts/icon/location-building-icon.svg');
  }
  
  button.image-button.nest {
    background-image: url('/asserts/icon/location-nest-icon.svg');
  }

  button.image-button.elz {
    background-image: url('/asserts/icon/location-elz-icon.svg');
  }
}
</style>