<template>
  <input class="location-search"
         :value="value"
         @input="inputValue"
         :placeholder="placeholder"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SearchPlaceLatitudeLongitude',
  data() {
    return {
      latitude: null,
      longitude: null,
      error: null,
    };
  },
  props: {
    value: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
  },
  methods: {
    inputValue(event) {
      let value = event.target.value;

      if (typeof(value) !== "string") {
        this.latitude = null;
        this.longitude = null;
        this.$emit('update:value', '');
        return;
      }

      value = value.trim().toUpperCase();
      this.$emit('update:value', value);

      let latLng;
      let splited = false;
      if (value.indexOf(',') >= 0) {
        latLng = value.split(',');
        if (latLng.length > 2) {
          this.latitude = null;
          this.longitude = null;
          this.error = 'tooManyComma';
          return;
        }
        splited = true;
      } else {
        let indexOfNSEW = [
          value.indexOf("N"),
          value.indexOf("S"),
          value.indexOf("E"),
          value.indexOf("W"),
        ];
        
        for (let wayIndex of indexOfNSEW) {
          if (wayIndex >= 0 && wayIndex !== value.length - 1) {
            latLng = [
              value.substring(0, wayIndex + 1),
              value.substring(wayIndex + 1)
            ];
            splited = true;
            break;
          }
        }
      }

      if (!splited) {
        latLng = value.split(' ');
        if (latLng.length > 2) {
          latLng = latLng.reduce((acc, value) => {
            if (value !== "") {
              acc.push(value);
            }
            return acc;
          }, []);
        }

        if (latLng.length > 2) {
          this.latitude = null;
          this.longitude = null;
          this.error = 'tooManySpace';
          return;
        }
      }

      if (splited === false) {
        this.latitude = null;
        this.longitude = null;
        this.error = 'noSeprator';
        return;
      }

      // if input 0°W 51°47'69.6"N
      if (latLng[0].indexOf('W') >= 0|| latLng[0].indexOf('E') >= 0) {
        latLng.reverse();
      }

      // if input 0° 51°47'69.6"N
      if (latLng[1].indexOf('N') >= 0 || latLng[1].indexOf('S') >= 0) {
        latLng.reverse();
      }

      latLng = latLng.map(value => {
        value = value.trim();
        let negative = false;
        if (value.startsWith('-') || value.endsWith('S') || value.endsWith('W') ) {
          negative = true;
        }

        if (value.startsWith('-')) {
          value = value.substring(1, value.length)
        }
        if (value.endsWith('N') || value.endsWith('W') || value.endsWith('S') || value.endsWith('E') ) {
          value = value.substring(0, value.length - 1);
        }

        let degree = 0;
        if (value.indexOf('°') >= 0) {
          // DMS or DMM seprate by symbols
          if (!value.includes('\'')) {
            value = value + '0\'';
          }
          if (!value.includes('"')) {
            value = value + '0\"';
          }
          if (negative) {
            degree -= parseFloat(value.substring(0, value.indexOf('°'))) / Math.pow(60, 0);
            degree -= parseFloat(value.substring(value.indexOf('°') + 1, value.indexOf('\''))) / Math.pow(60, 1);
            degree -= parseFloat(value.substring(value.indexOf('\'') + 1, value.indexOf('"'))) / Math.pow(60, 2);
          } else {
            degree += parseFloat(value.substring(0, value.indexOf('°'))) / Math.pow(60, 0);
            degree += parseFloat(value.substring(value.indexOf('°') + 1, value.indexOf('\''))) / Math.pow(60, 1);
            degree += parseFloat(value.substring(value.indexOf('\'') + 1, value.indexOf('"'))) / Math.pow(60, 2);
          }
          return degree;
        } else if (value.indexOf(' ') >= 0) {
          // DMS or DMM seprate by space
          let pow = 0;

          while (value.length > 0) {
            let spacePosition = value.indexOf(' ');
            if (spacePosition === -1) {
              spacePosition = undefined;
            }
            let partValue = value.substring(0, spacePosition);

            if (negative) {
              degree -= parseFloat(partValue) / Math.pow(60, pow);
            } else {
              degree += parseFloat(partValue) / Math.pow(60, pow);
            }
            value = value.substring(partValue.length + 1);
            pow += 1;
          }
          return degree;
        } else {
          // DD
          return parseFloat(value);
        }
      });

      this.error = null;
      this.latitude = latLng[0];
      this.longitude = latLng[1];
    },
    search() {
      this.mapPanTo({
        latitude: this.latitude,
        longitude: this.longitude,
      });
    },
    ...mapActions({
      mapPanTo: 'map/panTo',
      mapFitBounds: 'map/fitBounds',
    }),
  },
}
</script>