<template>
  <div class="overlay location-map-title">
    <input class="location-name" v-model="locationName" disabled v-show="locationName != null" />
    <div class="search">
      <component :is="searchComponent" 
                 v-model="keyword"
                 @selectPlace="place => $emit('selectPlace', place)"
                 v-bind:placeholder="placeholder"
                 ref="search"
      />
      <button class="image-button clear" v-if="keyword" v-on:click="clearKeyword" />
      <button class="image-button search" v-on:click="searchKeyword" />
    </div>
  </div>
</template>

<script>
  import { GeographicLonger } from '@/utils/map.js';
  import GoogleImplement from './SearchPlaceGoogle.vue';
  import NominatimImplement from './SearchPlaceNominatim.vue';
  import LatitudeLongitudemplement from './SearchPlaceLatitudeLongitude.vue';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'SearchBar',
    data() {
      return {
        keyword: '',
      };
    },
    props: {
      locationName: {
        required: false,
      },
    },
    computed: {
      searchComponent() {
        switch (this.mapEngine) {
          case 'LatitudeLongitude':
            return LatitudeLongitudemplement;
          case 'Nominatim':
            return NominatimImplement;
          case 'GoogleMaps':
          default:
            return GoogleImplement;
        }
      },
      placeholder() {
        switch (this.mapEngine) {
          case 'LatitudeLongitude':
            return this.$t('page.setting.locationTab.mapPage.latLngHere');
          case 'Nominatim':
          case 'GoogleMaps':
          default:
            return this.$t('page.setting.locationTab.mapPage.searchHere');
        }

      },
      ...mapGetters({
        mapEngine: 'user/placeAPI',
      }),
    },
    methods: {
      clearKeyword() {
        this.keyword = null;
      },
      searchKeyword() {
        if (typeof(this.$refs.search.search) === 'function') {
          this.$refs.search.search();
        }
      }
    },
  };
</script>

<style lang="scss">
.overlay.location-map-title {
  top: 14px;
  left: 50px;
  right: 50px;
  height: 34px;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
  // background-color: white;
  display: flex;
  flex-direction: row;

  input {
    font-size: 18px;
    height: 34px;
    border-radius: 4px;
    border: solid 1px #d2d2d2;
    padding-left: 8px;
    padding-right: 8px;
  }

  input:disabled {
    background-color: rgba(0, 0, 0, 0.5);
    border: 0;
    color: white;
  }

  input.location-name {
    margin-right: 15px;
  }

  .search {
    width: 380px;
    // flex-grow: 1;
    height: 34px;
    position: relative;

    button {
      position: absolute;
      top: 0;
      height: 34px;
    }

    button.clear {
      left: 8px;
    }

    button.search {
      right: 8px;
      width: 18px;
      background-image: url('/asserts/icon/location-searching-icon.svg');
      background-repeat: no-repeat;
    }
    
    input.location-search {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
</style>